.list-content{
  height : 62vh;
  overflow-x :scroll;
  overflow-y: auto;
  border: 0;  
  transition: width 1s ease-in-out, height 1s ease-in-out;
}

.list-content-max{
  height : 75vh;
  overflow-x :scroll;
  overflow-y: auto;
  border: 0;  
  transition: width 1s ease-in-out, height 1s ease-in-out;
}

.list-header{
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 1); /* Set the initial opacity of the header */
  /* transition: background-color 0.3s ease-in-out;  */
  border-bottom:  0.5px solid rgb(146, 146, 146);
  
}

.list-header-cell{  
  background-color: rgba(255, 255, 255, 0.5); /* Set the initial opacity of the header */
  transition: background-color 0.3s ease-in-out; 
}

.cell{
    min-width: 150px;
    overflow: hidden;
}

.alternate {
    background-color: #0000000a;
    border-radius: 5px;
}

.search{
    
}

.searchInput{
    max-width: 250px;
}

.menu{
    position:absolute;
    top: 6px;
    right: 6px;

}

.optionsBar-item.has-dropdown.is-active .optionsBar-link, .optionsBar-item.has-dropdown:focus .optionsBar-link, .optionsBar-item.has-dropdown:hover .optionsBar-link {
    background-color: #fafafa;
}

.optionsBar-menu {
    flex-grow: 1;
    flex-shrink: 0;
}


  .optionsBar-item, .optionsBar-link {
    align-items: center;
    display: flex;
  }

  .optionsBar-item, .optionsBar-link {
    color: #4a4a4a;
    display: block;
    line-height: 1.5;
    padding: .5rem .75rem;
      padding-right: 0.75rem;
    position: relative;
  }


  .hoverMenu{
      position: absolute;
      background-color:#f0f0f0;
      z-index: 2;
  }

  .hoverMenu-item:hover{
    background-color: #dbdbdb;
  }

  .hoverMenu-divider{
    background-color: #4a4a4a;
    padding: 0;
    margin: 0;
    width: 100%;
}