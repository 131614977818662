:root {
    --main-white-color: white;
    --main-black-color: black;
    --main-blue-color: blue;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fff;
    max-width: 60%;
    padding: 20px;
    border-radius: 4px;
    overflow: hidden;
    display: flex !important;
    flex-direction: column;
    max-height: 90vh;
    min-height: 70vh;
    /* overflow-y: visible; */
    z-index: 1;
}

.modal-scroll {
    flex-grow: 1;
    overflow-y: auto;
    padding-bottom: 100px;
}

.modal-bottom {
    margin-top: 20px;
    text-align: center;
    clear: both;
    bottom: 1em;
    left: 1em; 
    min-width: 30%;
    padding: .5em;
}

.modal-top {
    margin-top: 20px;
    text-align: right;
    clear: both;
    top: 1em;
    right: 1em;
    min-width: 30%;
    padding: .5em;
}


.modal-title {
    display: block;
    color: var(--main-black-color);
    font-size: 1.2em;
    text-align: center;
    padding: 5px;
}

.modal-btn-pos {
    clear: both;
    bottom: 1em;
    right: 1em;
    min-width: 30%;
    padding: .5em;
}

.modal-btn {
    border-radius: 1rem;
    font-size: 1rem;
    padding: .5em;
    border: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    outline: none;
    margin-left: 1rem;
    margin-top: 1rem;
}

.modal-btn:hover {
    color: white;
    font-weight: bold;
    cursor: pointer;
    background: var(--main-blue-color);
}