.map-content {
  height: 75vh;
  overflow-x: scroll;
  overflow-y: auto;
  border: 0;
  transition: width 1s ease-in-out, height 1s ease-in-out;
}

.map-content-max {
  height: 90vh;
  overflow-x: scroll;
  overflow-y: auto;
  border: 0;
  transition: width 1s ease-in-out, height 1s ease-in-out;
}

.alternate {
  background-color: #00000006;
}


.searchInput {
  max-width: 250px;
}

.menu {
  position: absolute;
  top: 6px;
  right: 6px;

}

.optionsBar-item.has-dropdown.is-active .optionsBar-link,
.optionsBar-item.has-dropdown:focus .optionsBar-link,
.optionsBar-item.has-dropdown:hover .optionsBar-link {
  background-color: #fafafa;
}

.optionsBar-menu {
  flex-grow: 1;
  flex-shrink: 0;
}


.optionsBar-item,
.optionsBar-link {
  align-items: center;
  display: flex;
}

.optionsBar-item,
.optionsBar-link {
  color: #4a4a4a;
  display: block;
  line-height: 1.5;
  padding: .5rem .75rem;
  padding-right: 0.75rem;
  position: relative;
}


.hoverMenu {
  position: absolute;
  background-color: #f0f0f0;
  z-index: 2;
}

.hoverMenu-item:hover {
  background-color: #dbdbdb;
}

.hoverMenu-divider {
  background-color: #4a4a4a;
  padding: 0;
  margin: 0;
  width: 100%;
}

.wrapper-menu {
  flex-grow: 1;
}

.wrapper-title {
  font-weight: 700;
}

.mapMarker {
   background-color: transparent; 
   text-align: center;
   line-height: 0px;
}

/* .mapMarkerIcon {
  animation: rotate 2s linear infinite;
} */

.mapMarkerIconSelected {
  transform-origin: center;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#contextmenu{
  position: absolute;
  z-index: 100;
}

.contextmenu-title{
  font-weight: 700;
}


#right-toolbar {
  position: absolute;
  top: 90px; /* Adjust the top position as needed */
  left: 11px; /* Adjust the right position as needed */
  z-index: 1000; /* Ensure it's above the map */
}

.toolbar{
  border-radius: 3px;
}

.toolbar-item:not(:last-child){
  border-bottom: 1px solid #d2d2d2;
}
