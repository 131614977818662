  .no-suggestions {
      color: #999;
      padding: 0.5rem;
  }

  .suggestions {
      /* border: 1px solid #999;
      border-top-width: 0;
      list-style: none;
      margin-top: 0;
      max-height: 143px;
      overflow-y: auto;
      padding-left: 0;
      width: calc(300px + 1rem); */
      /* position: relative;
      display: inline-block; */
     
  }

  /* .suggestions ul {

} */

  /* .suggestions li {
      padding: 0.5rem;

  } */

  .suggestion-active,
  .suggestions li:hover {
      background-color: hsl(0, 0%, 21%);
      color: #f7f7f7;
      /* cursor: pointer;
      font-weight: 700; */
  }

  .suggestions li:not(:last-of-type) {
      border-bottom: 0.5px solid #999999;
  }



  .navbar-item{
    flex-shrink: 1;
}