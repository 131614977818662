.map-content{
  height : 75vh;
  overflow-x :scroll;
  overflow-y: auto;
  border: 0;
  transition: width 1s ease-in-out, height 1s ease-in-out;
}

.map-content-max{
  height : 90vh;
  overflow-x :scroll;
  overflow-y: auto;
  border: 0;
  transition: width 1s ease-in-out, height 1s ease-in-out;
}

.alternate {
    background-color: #00000006;
}


.searchInput{
    max-width: 250px;
}

.menu{
    position:absolute;
    top: 6px;
    right: 6px;

}

.optionsBar-item.has-dropdown.is-active .optionsBar-link, .optionsBar-item.has-dropdown:focus .optionsBar-link, .optionsBar-item.has-dropdown:hover .optionsBar-link {
    background-color: #fafafa;
}

.optionsBar-menu {
    flex-grow: 1;
    flex-shrink: 0;
}


  .optionsBar-item, .optionsBar-link {
    align-items: center;
    display: flex;
  }

  .optionsBar-item, .optionsBar-link {
    color: #4a4a4a;
    display: block;
    line-height: 1.5;
    padding: .5rem .75rem;
      padding-right: 0.75rem;
    position: relative;
  }


  .hoverMenu{
      position: absolute;
      background-color:#f0f0f0;
      z-index: 2;
  }

  .hoverMenu-item:hover{
    background-color: #dbdbdb;
  }

  .hoverMenu-divider{
    background-color: #4a4a4a;
    padding: 0;
    margin: 0;
    width: 100%;
}