.rangelist-content{
  height : 90vh;
  overflow-x :scroll;
  overflow-y: auto;
  border: 0;  
}

.rangelist-header{
  position: sticky;
  top: 0;
  z-index: 1;
  /* background-color: rgba(255, 255, 255, 1); /* Set the initial opacity of the header */
  /* transition: background-color 0.3s ease-in-out;  */
}

.alternate {
    background-color: #00000006;
}

.searchInput{
    max-width: 250px;
}

.item:hover{
  font-size: 0.85rem;
  background-color: #485fc7;
  color: #fff;
}

