.leaflet-container {
    width: 50vw;
    /* height: 50vh; */
}

.menu{
    position: relative;
}

.menu-item{
    padding-left: 1em;
}
