#pp-map{
    /* /* width: 100em; */
    /* height: 100em; */
    z-index: 0;
    width: 100%;
}

.pp-map-hide{
  display:none;
}

.no-scroll{
  overflow:hidden;
}

.map{
  overflow: auto;
  height: 90vh;
}


.filter{
  overflow-y: auto;
  height: 74vh;
}

.filter-item{
  overflow-x: hidden;
}



/* html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  #mainContainer{
      position: relative;
      width: 100%;
      height: calc(100% - 80px);
      display: flex;
  }
  
  #bars{
      z-index: 0;
      width: 500px;
      overflow-x: auto;
      border-left: 1px solid rgb(214, 49, 65);
  }
  
  ::-webkit-scrollbar {
      display: none;
  }
  
  #mapDiv {
      flex: 1;
  }
  #map {
      width: 100%;
      height: 100%;
      z-index: 0;
  } */
  
  .mapButton{
      width: 40px;
      height: 40px;
      opacity: 0.6;
      text-align: center;
      z-index: 500;
  }

  .mapButton:hover{
      opacity: 0.8;
      cursor: pointer;
  }

  .selected{
    opacity: 0.8;
   }
  

.darkmode {
    background-color: #1a1a1a;
}

/*   
  @media screen and (max-width: 500px) { 
    #mainContainer {
      flex-direction: column; 
    }
    #mapDiv,
    #bars {
      flex: 1;
    }
  } */