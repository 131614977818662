.dropdown-menu {
  position: absolute;
  z-index: 1; /* Ensure the dropdown is above other elements */
}

.dropdown-content {
  position: absolute;
  background-color: #fff;
  max-height: 200px; /* Maximum height of the dropdown */
  overflow-y: auto;
}