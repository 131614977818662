.hmenu {
    position: relative;
    font-size: .8rem;
    
}

.hmenu ul{
    list-style:none !important;
}



.hmenu-label{
    font-weight: 500 !important;
}

.hmenu-root {
    border-radius: 2px;
    box-shadow: 0 .25em 0.5em -.0625em rgba(10, 10, 10, 0.042),0 0 0 1px rgba(10,10,10,.02);
}

.hmenu-item {
    padding-left: 1em;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    background-color: rgba(0, 0, 0, 0.02);
    box-shadow: 0 .125em 0.25em -.03125em rgba(10, 10, 10, 0.042),0 0 0 1px rgba(10,10,10,.02);
}

.hmenu-item.is-active {
    color: #485fc7;
    background-color: aliceblue;
}


.hmenu-item:hover {
    font-size: .85rem;
}