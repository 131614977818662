.parentMenu{
  padding-left: 0.2rem;
}

/* .navbar-dropdown{
  padding: 0.5rem 0.5rem 0 0.2rem;
  margin: 0;
}

.navbar-item{
  padding: 0.5rem 0.5rem 0 0.2rem;
  margin: 0;
} */

.navbar-dropdown .navbar-item {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.15rem;
  padding-top: 0.15rem;
}

#menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

#menu .menu-item{
  padding: 0;
  margin: 0;  
  
}

#menu .menu-item-label{
  padding: 0 0 0.5rem 0.2rem;
  margin: 0.1rem;  
  border-bottom: 0.05rem solid #000;
 
}

#menu .menu-item-label:hover{
  padding: 0 0 0.3rem 0.1rem;
  background-color: #e1e1e1;
}

#menu .childrenMenu{
  padding: 0 0 0 0.1rem;
  margin: 0;  
}



