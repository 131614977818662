body {
  font-size: 0.8em !important;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button{
  font-size: .75rem;
}

.button.headericon{
  font-size: .40em;
}


.button.is-danger.is-inverted {
  background-color: #00000000;
  color: #f14668;
}

.on-hover:hover {
  background-color: #FAFCFF;
  color:00080F;
  border-radius: 1em;
}

.clickable {
  color: #205389;
  cursor: pointer;
}

.ModalFooter {
  display:block;
}

.card {
  border-width: 0.5px !important;
}

/* .button.is-loading::after, .control.is-loading::after, .loader, .select.is-loading
  ::after {
  animation: spinAround .5s infinite linear;
  border: 2px solid #dbdbdb;
    border-top-color: rgb(219, 219, 219);
    border-right-color: rgb(219, 219, 219);
  border-radius: 9999px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 1em;
  position: relative;
  width: 1em;
} */

.tooltip {
  height: 3em;
  width: 10em;
  background: yellow;
}



.smallText {
  font-size: 0.8rem;
}

.edittable {
  cursor:text;
}


.topRight {
  top:0px;
  right:0px;
  height: 500px;
  width: 500px;
  position: absolute;
}

.box {
  background-color: #fafafa;
  border: 0 !important
}

.card { 
  background-color: #fafafa;
  border: 0 !important
}

.label {
   font-weight: 500;
   font-size: .75rem;
  }

.visible { display: block;}

.hidden { display: none}

.fullHeight { height: 100%}

.fullWidth { width: 100%}

.input .textarea { 
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  font-size: .75rem;
}

.is-small {
  font-size: .75rem;
}

.control {
  font-size: .75rem;
}

.link:hover {
  font-size: .9rem;
}

.columns{
  transition: width 1s ease-in-out, height 1s ease-in-out;
}

.column{
  transition: width 1s ease-in-out, height 1s ease-in-out;
}

.column.is-none {
  flex: none;
  width: 0%;
}


.listitem:hover{
  font-size: 0.85rem;
  background-color: hsl(0, 0%, 96%);
  color: hsl(229, 53%,  53%);
}

.listitem:active{
  font-size: 0.9rem;
}


/* Bulma Style Override */

.panel-heading{
  font-size: 0.8rem;
}

.panel{
  font-size: 0.8rem;
}

.navbar-dropdown {
  font-size: .8rem;
}

.header-label {
  font-weight: 700 !important;
}

.dialog-header {
  border-bottom: 0.5px solid #c1d8f1;
}

.dialog-buttons {
  overflow: hidden; /* Clear the float */
}

.dialog-button-right {
  float: right;
}

.tags:not(:last-child){
  margin-bottom: 0.1rem;
}


.button, .file-cta, 
.file-name, 
.input, 
.pagination-ellipsis, 
.pagination-link, 
.pagination-next, 
.pagination-previous, 
.select select, 
.textarea {
  font-size: 0.8rem;
}

.no-border {
  border: none !important;
  box-shadow: none !important;
}

/* Bulma Overrides */
.breadcrumb {
  font-size: 0.8rem;
  white-space: nowrap;
}

.breadcrumb li + li::before {
  margin-right: 5px;
  margin-left: 5px;
}

.card-header {
  z-index: 2;
}


.tabs li.is-active a {
  font-size: 1.1em;
  font-weight: 600;
}

.loader
  ::after {
  animation: spinAround .5s infinite linear;
  border: 2px solid #dbdbdb;
    border-top-color: rgb(219, 219, 219);
    border-right-color: rgb(219, 219, 219);
  border-radius: 9999px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 1em;
  position: relative;
  width: 1em;
}

/* Color Scheme */

.itemDark {
  /* color:#fafafa; */
  background-color: #e4e4e4;
}

.highlight {
  color : blue;
  background-color: #efefef;
}

.alternate {
  background-color: #f9f9f9;
}

.domainModel {
  font-weight: 600;
}

 /* End of Color Scheme */
